import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Navigate, useNavigate, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FaUserCircle, FaLock } from "react-icons/fa";
import { RxAvatar } from "react-icons/rx";
import Swal from "sweetalert2";

const Login = ({ location }) => {
  const token = sessionStorage.getItem("token") || false;
  const rtoken = sessionStorage.getItem("rtoken") || false;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [navigate, setNavigate] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post(
        "api/partner/signin",
        {
          username,
          password,
        },
        { withCredentials: false }
      );

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${data["accessToken"]}`;

      if (data) {
        sessionStorage.setItem("token", `${data["accessToken"]}`);
        sessionStorage.setItem("rtoken", `${data["refreshToken"]}`);
        setNavigate(true);
      } else {
        console.log("no token");
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        text: "Username หรือ รหัสผ่านไม่ถูกต้อง",
        preConfirm: () => {
          
        },
      });
    }
  };

  if( rtoken != false && token != false){
    return <Navigate to="/dashboard" />;
  }else if(navigate === true){
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      <div className="App-center d-flex justify-content-center align-items-center">
        <div className="card bg-dark text-white rounded-4">
          <div className="card-header text-center mt-3 mb-2 bg-dark fs-2 rounded-bottom rounded-4">
            <RxAvatar style={{ fontSize: "5rem" }} />
            <br></br>
            เข้าสู่ระบบพันธมิตร
          </div>
          <div className="card-body" style={{ minWidth: "350px" }}>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formTel">
                {/* <Form.Label>เบอร์โทรศัพท์</Form.Label> */}
                <div className="input-group">
                  <span className="input-group-text">
                    <FaUserCircle />
                  </span>
                  <Form.Control
                    type="text"
                    placeholder="Username"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                {/* <Form.Label>รหัสผ่าน</Form.Label> */}
                <div className="input-group">
                  <span className="input-group-text">
                    <FaLock />
                  </span>
                  <Form.Control
                    type="password"
                    placeholder="รหัสผ่าน"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </Form.Group>
              <hr />
              <Button
                className="btn-login"
                variant="primary w-100"
                type="submit"
              >
                เข้าสู่ระบบ
              </Button>
            </Form>
            <div className="text-center mt-3 d-flex justify-content-center">
              <p href="#" className="register-text">
                ยังไม่ได้เป็นสมาชิก ?{" "}
              </p>
              <Link to="/dashboard" className="register-text2 ms-1">
                สมัครสมาชิกใหม่
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
