import Navbarheader from "./header";
import SidebarLeft from "./sidebar-left";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { VscGraph } from "react-icons/vsc";
import { FaCalendarAlt, FaSearch } from "react-icons/fa";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import Table from "react-bootstrap/Table";
import "react-datepicker/dist/react-datepicker.css";
import "../assets/css/income-calculation.css";

function IncomeCalculation() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [userData, setUserData] = useState([]);

  return (
    <>
      <Navbarheader></Navbarheader>
      <div className="row">
        <SidebarLeft></SidebarLeft>
        <>
          <div
            className="col-12 col-lg-9 col-xl-10 bg-dark ps-2"
            style={{ fontSize: "medium", minHeight: "1040px", padding: "0" }}
          >
            <Container className="income-cal-container">
              <Row
                className="tab-header d-flex flex-row align-items-center"
                style={{ marginTop: "17px" }}
              >
                <Col className="d-flex">
                  <VscGraph className="icon-sm me-3 mt-1" />
                  <p className="fw-bold">คำนวณรายได้</p>
                </Col>
              </Row>

              <Row className="income_cal_wrapper">
                <Row className="income_cal_header d-flex">
                  <Col className="">
                    <p className="" style={{ fontSize: "smaller" }}>
                      เทิร์นโอเวอร์: 0.00 แพ้/ชนะ: 0.00 รายได้: 0.00
                    </p>
                  </Col>

                  <Col className="filter-table d-flex">
                    <div
                      className="bg-warning"
                      style={{ marginTop: "1px", marginBottom: "1px" }}
                    >
                      <FaCalendarAlt className="ms-3 me-3 mt-2" />
                    </div>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="วันเริ่มต้น"
                    />
                    <div
                      className="bg-warning"
                      style={{ marginTop: "1px", marginBottom: "1px" }}
                    >
                      <FaCalendarAlt className="ms-3 me-3 mt-2" />
                    </div>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      placeholderText="วันสิ้นสุด"
                    />
                    <div
                      className="btn btn-primary btn-sm pt-2"
                      style={{ width: "80px" }}
                    >
                      <FaSearch
                        className="me-2"
                        style={{ marginBottom: "3px" }}
                      />
                      ค้นหา
                    </div>
                  </Col>
                </Row>

                <Row className="income_cal_table mt-3">
                  <Col className="">
                    <div className="table-responsive">
                      <Table striped bordered hover>
                        <thead>
                          <tr
                            className="text-center"
                            style={{ fontSize: "0.8rem" }}
                          >
                            <th>ลำดับ</th>
                            <th>ยูสเซอร์เนม</th>
                            <th>รหัสสมาชิก</th>
                            <th>ชื่อ-นามสกุล</th>
                            <th>เทิร์นโอเวอร์</th>
                            <th>แพ้/ชนะ</th>
                            <th>รายได้</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userData.map((user) => (
                            <tr key={user.id}>
                              <td>{user.number}</td>
                              <td>{user.username}</td>
                              <td>{user.password}</td>
                              <td>{user.name}</td>
                              <td>{user.turnover}</td>
                              <td>{user.losewin}</td>
                              <td>{user.income}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </Row>
            </Container>
          </div>
        </>
      </div>
    </>
  );
}

export default IncomeCalculation;
