import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Dashboard from "./page/dashboard";
import Login from "./page/Authen/login";
import Logout from "./page/Authen/logout";
import Downline from "./page/downline";
import Member from "./page/member";
import Myprofile from "./page/myprofile";
import Report from "./page/report";

import IncomeCalculation from "./page/income-calculation";
import IncomeHistory from "./page/income-history";
import ReferFriend from "./page/refer-friend";
import MediaMarketing from "./page/media-marketing";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import DownlineMember from "./page/downline-member";





const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/member",
    element: <Member />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/downline",
    element: <Downline />,
  },
  {
    path: "/downline/:id",
    element: <DownlineMember />,
  },
  {
    path: "/reports/income-calculation",
    element: <IncomeCalculation />,
  },
  {
    path: "/reports/income-history",
    element: <IncomeHistory />,
  },
  {
    path: "/marketing/refer-friend",
    element: <ReferFriend />,
  },
  {
    path: "/marketing/media-marketing",
    element: <MediaMarketing />,
  },
  {
    path: "/myprofile",
    element: <Myprofile />,
  },
  {
    path: "/report",
    element: <Report />,
  },
  {
    path: "/report/:id",
    element: <Report />,
  },
]);

const App = () => {

  return (
    <div className="App">
      <header className="App-header" style={{ minHeight: "100vh" }}>
        <RouterProvider router={router} />
      </header>
    </div>
  );
}

export default App;
