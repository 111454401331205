import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDashboard } from "@fortawesome/free-solid-svg-icons";
import { FaUsers , FaUser } from "react-icons/fa";
import { VscGraph } from "react-icons/vsc";
import { useLocation, Link } from "react-router-dom";

function OffcanvasMenu() {
  const location = useLocation();
  const [isMarketingExpanded, setisMarketingExpanded] = useState(true);
  const [isReportsExpanded, setIsReportsExpanded] = useState(true);

  // useEffect(() => {
  //   const startReportsClick = () => {
  //     setIsReportsExpanded(false);
  //   };
  //   startReportsClick();
  // }, []);

  const handleMarketingClick = () => {
    setisMarketingExpanded(!isMarketingExpanded);
  };
  const handleReportsClick = () => {
    setIsReportsExpanded(!isReportsExpanded);
  };

  const isActive = (pathname) => {
    return location.pathname === pathname ? "active" : "";
  };

  return (
    <>
      <ul className="nav nav-pills flex-column mb-auto p-2 mt-2">
        <li>
          <Link
            to="/dashboard"
            className={`nav-link text-white ${isActive("/dashboard")}`}
          >
            <FontAwesomeIcon icon={faDashboard} /> แดชบอร์ด
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/member"
            className={`nav-link text-white ${isActive("/member")}`}
          >
            <FaUser /> สมาชิก
          </Link>
        </li>
        {/* <li className="nav-item">
          <div
            className={`nav-link text-white`}
            onClick={handleMarketingClick}
            style={{ cursor: "pointer" }}
          >
            <VscGraph /> รายงาน
          </div>
          <ul
            className={`nav flex-column ps-3 ${
              isMarketingExpanded ? "show" : ""
            }`}
          >
            
            <li className="nav-item">
              <Link
                to="/marketing/refer-friend"
                className={`nav-link text-white ${isActive(
                  "/marketing/refer-friend"
                )}`}
                style={{ display: isMarketingExpanded ? "block" : "none" }}
              >
                - แนะนำเพื่อน
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/marketing/media-marketing"
                className={`nav-link text-white ${isActive(
                  "/marketing/media-marketing"
                )}`}
                style={{ display: isMarketingExpanded ? "block" : "none" }}
              >
                - สื่อการตลาด
              </Link>
            </li>
          </ul>
        </li> */}
        <li className="nav-item">
              <Link
                to="/report/"
                className={`nav-link text-white ${isActive(
                  "/report/"
                )}`}
                style={{ display: isMarketingExpanded ? "block" : "none" }}
              >
                <VscGraph /> รายงาน
              </Link>
            </li>
        <li className="nav-item">
          <Link
            to="/downline"
            className={`nav-link text-white ${isActive("/downline")}`}
          >
            <FaUsers /> สายงาน
          </Link>
        </li>
        {/* <li className="nav-item">
          <div
            className={`nav-link text-white`}
            onClick={handleReportsClick}
            style={{ cursor: "pointer" }}
          >
            <VscGraph /> รายงาน
          </div>
          <ul
            className={`nav flex-column ps-3 ${
              isReportsExpanded ? "show" : ""
            }`}
          >
            <li className="nav-item">
              <Link
                to="/reports/income-calculation"
                className={`nav-link text-white ${isActive(
                  "/reports/income-calculation"
                )}`}
                style={{ display: isReportsExpanded ? "block" : "none" }}
              >
                - คำนวณรายได้
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/reports/income-history"
                className={`nav-link text-white ${isActive(
                  "/reports/income-history"
                )}`}
                style={{ display: isReportsExpanded ? "block" : "none" }}
              >
                - ประวัติการรับรายได้
              </Link>
            </li>
          </ul>
        </li> */}
      </ul>
    </>
  );
}

export default OffcanvasMenu;
