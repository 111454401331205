import Navbarheader from "./header";
import SidebarLeft from "./sidebar-left";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaCalendarAlt, FaSearch, FaUser } from "react-icons/fa";
import { useEffect, useState } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { format } from "date-fns-tz";
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import moment from "moment";

import Swal from "sweetalert2";

function Report() {
  const [navigate, setNavigate] = useState(false);
  const [userData, setUserData] = useState([]); // State เพื่อเก็บข้อมูลผู้ใช้
  const token = sessionStorage.getItem("token") || false;
  const [reportUserId, setReportUserId] = useState(null);
  const uid = useParams("id");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [startNameDate, setNameStartDate] = useState(null);
  const [endNameDate, setNameEndDate] = useState(null);

  const fetchData = async () => {
    console.log(typeof uid);

    if (typeof uid === "object" && Object.keys(uid).length > 0) {
      const idArray = Object.values(uid);
      try {
        const { data } = await axios.get(`/api/partner/report/${idArray[0]}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserData(data);
      } catch (e) {
        setUserData(false);
        //setNavigate(true);
      }
    } else {
      try {
        const { data } = await axios.get(`/api/partner/report`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserData(data);
      } catch (e) {
        setUserData(false);
        //setNavigate(true);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formattedFromDate = startDate
      ? moment(startDate).format("YYYY-MM-DDTHH:mm:ssZ")
      : "";
    const formattedToDate = endDate
      ? moment(endDate).format("YYYY-MM-DDT23:59:59Z")
      : "";

    setNameStartDate(moment(startDate).format("DD-MM-YYYY HH:mm:ss"));
    setNameEndDate(moment(endDate).format("DD-MM-YYYY HH:mm:ss"));

    const urlEncodedFromDate = formattedFromDate
      ? encodeURIComponent(formattedFromDate)
      : "";
    const urlEncodedToDate = formattedToDate
      ? encodeURIComponent(formattedToDate)
      : "";

    const selectedPS = document.querySelector('select[name="ps"]').value;

    try {
      const { data } = await axios.get(
        `/api/partner/member?pa=1&ps=${selectedPS}&from=${urlEncodedFromDate}&to=${urlEncodedToDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserData(data);
    } catch (e) {}

    console.log("Formatted From Date:", formattedFromDate);
    console.log("Formatted To Date:", formattedToDate);
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  if (navigate) {
    Swal.fire({
      icon: "error",
      text: "session was expire please login again.",
    });
    return <Navigate to="/logout" />;
  }

  console.log(userData);

  return (
    <>
      <Navbarheader></Navbarheader>
      <div className="row">
        <SidebarLeft></SidebarLeft>
        <>
          <div
            className="col-12 col-lg-9 col-xl-10 bg-dark"
            style={{ fontSize: "medium", minHeight: "1040px", padding: "0" }}
          >
            <Container className="downline-continer">
              <Row className="mx-0 d-flex">
                <div
                  className="tab-header d-flex flex-row align-items-center"
                  style={{ marginTop: "17px" }}
                >
                  <Col className="d-flex">
                    <FaUser className="icon-sm me-3 mt-1" />
                    <p className="fw-bold">รายงาน แพ้-ชนะ</p>
                  </Col>
                </div>
              </Row>
              {startNameDate !== null && endNameDate !== null && (
                <div className="alert alert-info">
                  Start: {startNameDate} - End: {endNameDate}
                </div>
              )}
              <div className="my-2 alert alert-primary">
                {moment(userData.fromTime).format(
                  "วันที่ เวลาเริ่มต้น : DD-MM-YYYY HH:mm:ss"
                )}{" "}
                -{" "}
                {moment(userData.toTime).format(
                  "วันที่ เวลาสิ้นสุด : DD-MM-YYYY HH:mm:ss"
                )}
              </div>
              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <td className="text-info">
                      <strong>ยอดทั้งหมด</strong>
                    </td>
                    <td className="text-info">
                      <strong>ส่วนแบ่ง</strong>
                    </td>
                    <td className="text-info">
                      <strong>ยอดส่วนแบ่ง</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ "border-top": "2px solid" }}>
                    <td className="text-warning">{userData.netAmount}</td>
                    <td className="text-warning">{userData.sharing}%</td>
                    <td className="text-warning">{userData.amount}</td>
                  </tr>
                </tbody>
              </Table>
              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>สายล่าง</th>
                    <th>ยอดได้เสีย</th>
                    <th>ส่วนแบ่ง</th>
                    <th>ยอดส่วนแบ่ง</th>
                    <th>ส่วนแบ่งสายล่าง</th>
                    <th>ยอดสายล่าง</th>
                  </tr>
                </thead>
                <tbody>
                  {userData &&
                    userData != undefined &&
                    userData.downline != undefined &&
                    userData.downline.map((user) => (
                      <tr key={user.id}>
                        <td>
                          <a href={`/report/${user.downlineId}`}>
                            {user.username}
                          </a>
                        </td>
                        <td>{user.netWinLoss}</td>
                        <td>{user.partnerShare}%</td>
                        <td>{user.partnerWinLoss}</td>
                        <td>{user.downlineShare}%</td>
                        <td>{user.downlineWinLoss}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Container>
          </div>
        </>
      </div>
    </>
  );
}

export default Report;
