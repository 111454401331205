import Navbarheader from "./header";
import SidebarLeft from "./sidebar-left";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaCalendarAlt, FaSearch, FaUser } from "react-icons/fa";
import { useEffect, useState } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { format } from "date-fns-tz";
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import Swal from "sweetalert2";

function Member() {
  const [navigate, setNavigate] = useState(false);
  const [userData, setUserData] = useState([]); // State เพื่อเก็บข้อมูลผู้ใช้
  const token = sessionStorage.getItem("token") || false;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [startNameDate, setNameStartDate] = useState(null);
  const [endNameDate, setNameEndDate] = useState(null);

  const fetchData = async () => {
    try {
      const { data } = await axios.get("/api/partner/member", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserData(data);
    } catch (e) {
      setUserData(false);
      setNavigate(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formattedFromDate = startDate
      ? moment(startDate).format("YYYY-MM-DDTHH:mm:ssZ")
      : "";
    const formattedToDate = endDate
      ? moment(endDate).format("YYYY-MM-DDT23:59:59Z")
      : "";

    setNameStartDate(moment(startDate).format("DD-MM-YYYY HH:mm:ss"));
    setNameEndDate(moment(endDate).format("DD-MM-YYYY HH:mm:ss"));

    const urlEncodedFromDate = formattedFromDate
      ? encodeURIComponent(formattedFromDate)
      : "";
    const urlEncodedToDate = formattedToDate
      ? encodeURIComponent(formattedToDate)
      : "";

    const selectedPS = document.querySelector('select[name="ps"]').value;

    try {
      const { data } = await axios.get(
        `/api/partner/member?pa=1&ps=${selectedPS}&from=${urlEncodedFromDate}&to=${urlEncodedToDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserData(data);
    } catch (e) {}

    console.log("Formatted From Date:", formattedFromDate);
    console.log("Formatted To Date:", formattedToDate);
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  if (navigate) {
    Swal.fire({
      icon: "error",
      text: "session was expire please login again.",
    });
    return <Navigate to="/logout" />;
  }

  return (
    <>
      <Navbarheader></Navbarheader>
      <div className="row">
        <SidebarLeft></SidebarLeft>
        <>
          <div
            className="col-12 col-lg-9 col-xl-10 bg-dark"
            style={{ fontSize: "medium", minHeight: "1040px", padding: "0" }}
          >
            <Container className="downline-continer">
              <Row className="mx-0 d-flex">
                <div
                  className="tab-header d-flex flex-row align-items-center"
                  style={{ marginTop: "17px" }}
                >
                  <Col className="d-flex">
                    <FaUser className="icon-sm me-3 mt-1" />
                    <p className="fw-bold">สามาชิก</p>
                  </Col>
                </div>
                <Form
                  action="/member"
                  className="datepickerform"
                  onSubmit={handleSubmit}
                >
                  <Col className="filter-table d-flex my-3">
                    <div
                      className="bg-warning text-black px-2"
                      style={{
                        marginTop: "1px",
                        marginBottom: "1px",
                        lineHeight: "35px",
                      }}
                    >
                      จำนวน:
                    </div>
                    <select name="ps">
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                    </select>

                    <div
                      className="bg-warning"
                      style={{ marginTop: "1px", marginBottom: "1px" }}
                    >
                      <FaCalendarAlt className="ms-3 me-3 mt-2" />
                    </div>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      name="from"
                      placeholderText="วันเริ่มต้น"
                    />
                    <div
                      className="bg-warning"
                      style={{ marginTop: "1px", marginBottom: "1px" }}
                    >
                      <FaCalendarAlt className="ms-3 me-3 mt-2" />
                    </div>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      name="to"
                      placeholderText="วันสิ้นสุด"
                    />
                    <div
                      className="btn btn-primary btn-sm pt-2"
                      style={{ width: "80px" }}
                      onClick={handleSubmit}
                    >
                      <FaSearch
                        className="me-2"
                        style={{ marginBottom: "3px" }}
                        type="submit"
                      />
                      ค้นหา
                    </div>
                  </Col>
                </Form>
              </Row>
              {startNameDate !== null && endNameDate !== null && (
                <div className="alert alert-info">
                  Start: {startNameDate} - End: {endNameDate}
                </div>
              )}
              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>Username</th>
                    <th>createdTime</th>
                  </tr>
                </thead>
                <tbody>
                  {userData &&
                    userData.data != undefined &&
                    userData.data.map((user) => (
                      <tr key={user.id}>
                        <td>{user.username}</td>
                        <td>
                          {format(
                            new Date(user.createdTime),
                            "วันที่: dd/MM/yyyy , เวลา: HH:mm:ss น.",
                            { timeZone: "Asia/Bangkok" }
                          )}
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td>
                      <strong>total</strong>
                    </td>
                    <td>{userData.total}</td>
                  </tr>
                </tbody>
              </Table>
            </Container>
          </div>
        </>
      </div>
    </>
  );
}

export default Member;
