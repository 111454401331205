import { Navigate } from "react-router-dom";


const Logout = ({ location }) => {
  sessionStorage.removeItem('token'); 
  sessionStorage.removeItem('rtoken'); 
  sessionStorage.removeItem('session_token');
  sessionStorage.removeItem('fromDate');
  sessionStorage.removeItem('endDate');

  return (
    <Navigate to="/" />
  );
};

export default Logout;
