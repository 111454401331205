import Navbarheader from "./header";
import SidebarLeft from "./sidebar-left";
import Main from "./main";
import { useEffect, useState } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import "../assets/css/dashboard.css";
import "../assets/css/header.css";
import "../assets/css/sidebar-left.css";

function Dashboard() {

  return (
    <>
      <Navbarheader></Navbarheader>
      <div className="row">
        <SidebarLeft></SidebarLeft>
        <Main></Main>
      </div>
    </>
  );
}

export default Dashboard;
