import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import OffcanvasMenu from "./offcanvas";
import { Navigate } from "react-router-dom";
import logo from "../assets/image/logo.svg";
import _ from "lodash";
import Swal from "sweetalert2";

//testwebhook git

function Navbarheader() {
  const [userdata, setUserdata] = useState(false);
  const [msg, setMsg] = useState(false);

  const [navigateToLogout, setNavigateToLogout] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const token = sessionStorage.getItem("token") || false;

    try {
      const { data } = await axios.get("/api/partner/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setUserdata(data);
      setLoading(false);
    } catch (e) {
      setMsg(e);
      setLoading(false);
      //setNavigateToLogout(true);
    }
  };

  const LoadingScreen = () => {
    return (
      <div className="loading-container">
        <div className="loading"></div>
      </div>
    );
  };

  //const debouncedFetchData = _.debounce(fetchData, 1000); // 1000 milliseconds debounce delay

  useEffect(() => {
    fetchData();
  }, []);

  if (navigateToLogout) {
    Swal.fire({
      icon: "error",
      text: "session was expire please login again.",
    });
    return <Navigate to="/logout" />;
  }

  if (loading) {
    return <LoadingScreen />;
  }

  if (!userdata) {
    return <Navigate to="/" />;
  }

  return (
    <>
      {[false].map((expand) => (
        <Navbar key={expand} expand={expand} className="">
          <Container fluid>
            <Navbar.Brand href="#home" className="text-light">
              <img
                alt=""
                src={logo}
                width="30"
                height="30"
                className="d-inline-block align-top"
              />{" "}
              168lionbet
            </Navbar.Brand>
            <Navbar.Toggle
              className="d-lg-none bg-white"
              aria-controls={`offcanvasNavbar-expand-${expand}`}
            />
            <div
              className="d-none d-lg-flex"
              style={{ fontSize: "medium", color: "white" }}
            >
              <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  {`${userdata.username}`}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="/myprofile">My Profile</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Nav.Link className="" href="/logout">
                <Button className="w-100 btn-dark ms-2">Logout</Button>
              </Nav.Link>
            </div>
            <Navbar.Offcanvas
              className="bg-dark text-white"
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header className="border-bottom" closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <div className="d-flex">
                    <span className="px-4">Login As</span>
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        {`${userdata.username}`}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="/myprofile">
                          My Profile
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 px-3">
                  <OffcanvasMenu></OffcanvasMenu>
                  <Nav.Link className="mt-4" href="/logout">
                    <Button className="w-100" variant="outline-light">
                      Logout
                    </Button>
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default Navbarheader;
