import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";


const CreateDownlineModal = ({ show, handleClose, handleCreateDownline }) => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    name: "",
    sharing: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const usernameRegex = /^[a-z][a-z0-9]{5,}$/; // ต้องขึ้นต้นด้วย a-Z และมี a-Z หรือ 0-9 อย่างน้อย 5 ตัวหลัง
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+={}\[\]|\\:;'"<>,.?/~`]).{8,}$/;
    const enteredUsername = formData.username;
    const enteredPassword = formData.password;


    if (!usernameRegex.test(enteredUsername)) {

      Swal.fire({
        icon: "error",
        text: "Username must start with at least 6 characters consisting of a-z (only Lower Case) and 0-9.",
      });
      return; 
    }
    if (!passwordRegex.test(enteredPassword)) {

      Swal.fire({
        icon: "error",
        text: "Password must contain at leat 8 and at leat 1 Upper Case and 1 Special Character.",
      });
      return; 
    }

    handleCreateDownline(formData);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Modal Create Downline</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="sharing">
            <Form.Label>sharing</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter sharing"
              name="sharing"
              value={formData.sharing}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Button className="mt-3" variant="primary" type="submit">
            Create Downline
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateDownlineModal;
