import React, { useState } from "react";
import Navbarheader from "./header";
import SidebarLeft from "./sidebar-left";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { VscGraph } from "react-icons/vsc";
import { BsFilterLeft } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from "react-bootstrap/Dropdown";
import "../assets/css/income-history.css";

function IncomeHistory() {
  const [userData, setUserData] = useState([]);
  const [dropdown, setDropdown] = useState(25);

  return (
    <>
      <Navbarheader></Navbarheader>
      <div className="row">
        <SidebarLeft></SidebarLeft>
        <>
          <div
            className="col-12 col-lg-9 col-xl-10 bg-dark ps-2"
            style={{ fontSize: "medium", minHeight: "1040px", padding: "0" }}
          >
            <Container className="refer-container">
              <Row
                className="tab-header d-flex flex-row align-items-center"
                style={{ marginTop: "17px" }}
              >
                <Col className="d-flex">
                  <VscGraph className="icon-sm me-3 mt-1" />
                  <p className="fw-bold">ประวัติการรับรายได้</p>
                </Col>
              </Row>

              <Row className="income_cal_wrapper">
                <Row className="income_cal_header d-flex">
                  <Col className="filter-table d-flex">
                    <div
                      className="btn_icon bg-warning"
                      style={{ marginTop: "1px", marginBottom: "1px" }}
                    >
                      <BsFilterLeft
                        className="ms-3 me-3 mt-2"
                        style={{ color: "black" }}
                      />
                    </div>
                    <Dropdown className="btn_dropdown">
                      <Dropdown.Toggle variant="dark">
                        {dropdown}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setDropdown(25)}>25</Dropdown.Item>
                        <Dropdown.Item onClick={() => setDropdown(50)}>
                          50
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setDropdown(100)}>
                          100
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>

                <Row className="income_cal_table mt-3">
                  <Col className="">
                    <div className="table-responsive">
                      <Table striped bordered hover>
                        <thead>
                          <tr
                            className="text-center"
                            style={{ fontSize: "0.8rem" }}
                          >
                            <th>ลำดับ</th>
                            <th>REF.</th>
                            <th>NICKNAME</th>
                            <th>ชื่อ-นามสกุล</th>
                            <th>เทิร์นโอเวอร์</th>
                            <th>แพ้/ชนะ</th>
                            <th>ยอดผลตอบแทน</th>
                            <th>รายได้</th>
                            <th>เดือน-ปี(รอบ)</th>
                            <th>วันที่เวลา</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userData.map((user) => (
                            <tr key={user.id}>
                              <td>{user.number}</td>
                              <td>{user.ref}</td>
                              <td>{user.nickname}</td>
                              <td>{user.name}</td>
                              <td>{user.turnover}</td>
                              <td>{user.losewin}</td>
                              <td>{user.totalreturn}</td>
                              <td>{user.income}</td>
                              <td>{user.monthyear}</td>
                              <td>{user.datetime}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </Row>
            </Container>
          </div>
        </>
      </div>
    </>
  );
}

export default IncomeHistory;
