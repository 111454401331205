import Navbarheader from "./header";
import SidebarLeft from "./sidebar-left";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { useEffect, useState } from "react";
import axios from "axios";
import ResetPasswordModal from "../components/member/CreateResetPassModal";
import Swal from "sweetalert2";

function Myprofile() {
  const [userData, setUserData] = useState([]); // State เพื่อเก็บข้อมูลผู้ใช้
  const [navigate, setNavigate] = useState(false);
  const token = sessionStorage.getItem("token") || false;
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get("/api/partner/me", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(data);
        setUserData(data);
      } catch (e) {
        setNavigate(true);
      }
    })();
  }, []);

  const handleResetPasswordModal = async (e) => {
    try {
      const response = await axios.post(
        "/api/partner/me/password",
        {
          oldPassword: e.oldPassword,
          newPassword: e.newPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Reset Password สำเร็จ",
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Reset Password ไม่สำเร็จ",
        });
      }
      console.log(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  if (navigate) {
    navigate("/login");
  }

  return (
    <>
      <Navbarheader></Navbarheader>
      <div className="row">
        <SidebarLeft></SidebarLeft>
        <>
          <div
            className="col-12 col-lg-9 col-xl-10 bg-dark"
            style={{ fontSize: "medium", minHeight: "1040px", padding: "0" }}
          >
            <Container className="mt-3">
              <div className="d-flex justify-content-between mb-3 downline-head">
                <h4>MyProfile</h4>
              </div>
              <div className="table-responsive" style={{ maxWidth: "450px" }}>
                <Table striped bordered hover variant="dark">
                  <tbody>
                    {userData && (
                      <>
                        <tr>
                          <td>Name</td>
                          <td>{userData.name}</td>
                        </tr>
                        <tr>
                          <td>Sharing</td>
                          <td>{userData.sharing}</td>
                        </tr>
                        <tr>
                          <td>Password</td>
                          <td>
                            <div
                              className="btn btn-info"
                              onClick={handleOpenModal}
                            >
                              Reset
                            </div>
                            <ResetPasswordModal
                              show={showModal}
                              handleClose={handleCloseModal}
                              handleResetPasswordModal={handleResetPasswordModal}
                            />
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </Table>
              </div>
            </Container>
          </div>
        </>
      </div>
    </>
  );
}

export default Myprofile;
