

import Navbarheader from "./header";
import SidebarLeft from "./sidebar-left";
import { useEffect, useState } from "react";
import axios from "axios";
import { Navigate , useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import CreateDownlineModal from "../components/member/CreateDownlineModal";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ResetPasswordDownlineModal from "../components/member/CreateResetPassDownlineModal";
import UpdateDownlineReferenceModal from "../components/member/UpdateDownlineReference";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import { FaPencilAlt } from "react-icons/fa";

function DownlineMember() {
  const [navigate, setNavigate] = useState(false);
  const [userData, setUserData] = useState([]); // State เพื่อเก็บข้อมูลผู้ใช้
  const token = sessionStorage.getItem("token") || false;
  const selfSharing = sessionStorage.getItem("selfSharing") || false;

  let { id } = useParams();

  const [showModal, setShowModal] = useState(false);
  const [showModalReset, setShowModalReset] = useState(false);
  const [showModalUpdateRef, setShowModalUpdateRef] = useState(false);
  const [resetUserId, setResetUserId] = useState(null);
  const [updateUserId, setUpdateUserId] = useState(null);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [sharing, setUserSharing] = useState(null);
  const [sharingId, setUserSharingId] = useState(null);
  const [sharingCount, setUserSharingCount] = useState((Array.from({ length: selfSharing }, (_, index) => index + 1)));
  const [sharingName, setUserSharingName] = useState(null);

  const [formData, setFormData] = useState({
    userId: "",
    sharing: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const fetchData = async () => {
    try {
      const { data } = await axios.get(`/api/partner/downline/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserData(data);
      // console.log(data);
    } catch (e) {
      setNavigate(true);
    }
  };

  const handleCreateDownline = async (e) => {
    try {
      const response = await axios.post(
        `/api/partner/downline/${id}`,
        {
          username: e.username,
          password: e.password,
          name: e.name,
          sharing: e.sharing,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status == 200) {
        fetchData();
        Swal.fire({
          icon: "success",
          text: "เพิ่ม Downline สำเร็จ",
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "เพิ่ม Downline ไม่สำเร็จ",
        });
      }
      console.log(response);
    } catch (error) {
      fetchData();
      console.error("Error fetching data:", error);
    }
  };

  const handleResetPasswordDownlineModal = async (e) => {
    console.log();
    try {
      const response = await axios.post(
        `/api/partner/downline/${resetUserId}/password`,
        {
          newPassword: e.newPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status == 200 || response == true) {
        Swal.fire({
          icon: "success",
          text: "Reset Password สำเร็จ",
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Reset Password ไม่สำเร็จ",
        });
      }
      console.log(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleUpdateDownlineRefModal = async (e) => {
    try {
      const response = await axios.post(
        `/api/partner/downline/${updateUserId}/reference`,
        {
          reference: e.reference,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status == 200) {
        fetchData();
        Swal.fire({
          icon: "success",
          text: "Update Downline สำเร็จ",
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Update Downline ไม่สำเร็จ",
        });
      }
      console.log(response);
    } catch (error) {
      fetchData();
      console.error("Error fetching data:", error);
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModalReset = (userId) => {
    setShowModalReset(true);
    setResetUserId(userId);
  };

  const handleOpenModalEditShare = (userId , sharing, uname , uplione_sharing) => {
    setShowModalEdit(true);
    setUserSharingId(userId);
    setUserSharing(sharing);
    setUserSharingName(uname);
    // setUserSharingCount((Array.from({ length: sharing }, (_, index) => index + 1)));
    setUserSharingCount((Array.from({ length: uplione_sharing }, (_, index) => index + 1)));

  };

  const handleCloseModalReset = () => {
    setShowModalReset(false);
    setResetUserId(null);
  };

  const handleOpenModalUpdate = (userId) => {
    setShowModalUpdateRef(true);
    setUpdateUserId(userId);
  };

  const handleCloseModalUpdate = () => {
    setShowModalUpdateRef(false);
    setUpdateUserId(null);
  };

  const handleOpenModalEdit = () => {
    setShowModalEdit(true);
  };

  const handleCloseModalEdit = () => {
    setShowModalEdit(false);
  };
  
  const handleSubmitEdit = async (e) => {
    try {
      const response = await axios.post(
        `/api/partner/downline/${e.target.userid.value}/sharing`,
        {
          sharing: e.target.share.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const expandText = (event) => {
    event.target.style.maxWidth = "none"; // ใช้ event.target เพื่อเข้าถึง element ที่ถูกคลิก
    event.target.onclick = null;
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  if (token != false) {
  } else {
    return <Navigate to="/" />;
  }

  console.log(userData);

  return (
    <>
      <Navbarheader></Navbarheader>
      <div className="row">
        <SidebarLeft></SidebarLeft>
        <>
          <div
            className="col-12 col-lg-9 col-xl-10 bg-dark"
            style={{ fontSize: "medium", minHeight: "1040px", padding: "0" }}
          >
            <Container className="downline-continer">
              <div className="row mt-3 downline-row">
                <div className="col downline-col">
                  <div className="d-flex justify-content-between mb-3 downline-head ">
                    <Breadcrumb
                      className="d-inline-block p-2 bg-light rounded"
                      style={{ filter: "invert(1) hue-rotate(153deg)" }}
                    >
                      <Breadcrumb.Item href={`/downline`}>
                        downline
                      </Breadcrumb.Item>
                      {userData.upline && userData.upline.length > 0 ? (
                        userData.upline.map((user) => (
                          <Breadcrumb.Item
                            active
                            className="text-decoration-none text-primary"
                          >
                            <a href={`/downline/${user.id}`}>{user.username}</a>
                            
                          </Breadcrumb.Item>
                        ))
                      ) : (
                        <></>
                      )}
                      <Breadcrumb.Item active>
                        {userData.username}
                      </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="btn btn-info" onClick={handleOpenModal}>
                      + Create Child Downline
                    </div>
                  </div>

                  <CreateDownlineModal
                    show={showModal}
                    handleClose={handleCloseModal}
                    handleCreateDownline={handleCreateDownline}
                  />
                  <div className="table-responsive">
                    <Table striped bordered hover variant="dark">
                      <thead>
                        <tr>
                          <th>Username</th>
                          <th>Name</th>
                          <th>Level</th>
                          {/* <th>Upline</th> */}
                          {/* <th>Downline</th> */}
                          <th>Sharing</th>
                          <th>Reference</th>
                          <th>Status</th>
                          <th>command</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userData.downline && userData.downline.length > 0 ? (
                          userData.downline.map((user) => (
                            <tr key={user.id}>
                              <td>
                                <a href={`/downline/${user.id}`}>
                                  {user.username}
                                </a>
                              </td>
                              <td>{user.name}</td>
                              <td>
                                {user.level === 1
                                  ? "Partner"
                                  : user.level === 2
                                  ? "Senior"
                                  : user.level === 3
                                  ? "Master"
                                  : user.level === 4
                                  ? "Agent"
                                  : user.level === 5
                                  ? "Marketing"
                                  : ""}
                              </td>
                              {/* <td>{user.upline}</td> */}
                              {/* <td>
                                <pre className="ellipsis" onClick={expandText}>
                                  {user.downline}
                                </pre>
                              </td> */}
                              <td>{user.sharing}
                              {console.log(user)}
                              <button
                                className="btn btn-info p-1 py-0 mx-2"
                                onClick={() =>
                                  handleOpenModalEditShare(
                                    user.id,
                                    user.sharing,
                                    user.username,
                                    userData.sharing,
                                  )
                                }
                                handleCloseModalEdit={handleCloseModalEdit}
                                handleOpenModalEdit={handleOpenModalEdit}
                              >
                                <FaPencilAlt />
                              </button>
                              </td>
                              <td>{user.reference}</td>
                              <td>{user.status}</td>
                              <td>
                                <div
                                  className="btn btn-info mx-2 my-1"
                                  onClick={() => handleOpenModalReset(user.id)}
                                >
                                  Reset
                                </div>
                                <div
                                  className="btn btn-info mx-2 my-1"
                                  onClick={() => handleOpenModalUpdate(user.id)}
                                >
                                  Update Ref
                                </div>
                                <ResetPasswordDownlineModal
                                  show={showModalReset}
                                  handleClose={handleCloseModalReset}
                                  handleResetPasswordDownlineModal={
                                    handleResetPasswordDownlineModal
                                  }
                                />
                                <UpdateDownlineReferenceModal
                                  show={showModalUpdateRef}
                                  handleClose={handleCloseModalUpdate}
                                  handleUpdateDownlineRefModal={
                                    handleUpdateDownlineRefModal
                                  }
                                />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <p>No data available</p>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
              <Modal show={showModalEdit} onHide={handleCloseModalEdit}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit Share</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form onSubmit={handleSubmitEdit}>
                    <Form.Group controlId="share">
                      <Form.Label>{sharingName} <strong>(Shared: {sharing} %)</strong></Form.Label>
                      <Form.Select
                        name="share"
                        onChange={handleChange}
                        value={formData.share}
                        required
                      >
                        <option value="false">Select an option</option>
                        {sharingCount.map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </Form.Select>
                      <input
                        type="hidden"
                        name="userid"
                        onChange={handleChange}
                        value={sharingId}
                      />
                    </Form.Group>
                    <Button className="mt-3" variant="primary" type="submit">
                      Save Change
                    </Button>
                  </Form>
                </Modal.Body>
              </Modal>
            </Container>
          </div>
        </>
      </div>
    </>
  );
}

export default DownlineMember;
