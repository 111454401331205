import axios from "axios";
import Swal from "sweetalert2";
import { Navigate } from "react-router-dom";

axios.defaults.baseURL = "https://partner.ox888.vip";
//axios.defaults.baseURL = "https://partner.pklaos88.online/";


let isRefreshing = false;

axios.interceptors.response.use(
  (resp) => resp,
  async (error) => {
    const token = sessionStorage.getItem("token") || false;
    const rtoken = sessionStorage.getItem("rtoken") || false;

    if (error.response.status === 401 && !isRefreshing) {
      try {
        isRefreshing = true;

        const response = await axios.post(
          "/api/partner/refresh",
          {
            accessToken: token,
            refreshToken: rtoken,
          },
          {
            withCredentials: false,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data == "invalid token" || !response.data) {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("rtoken");

          isRefreshing = false;
          console.log(1)
          Swal.fire({
            icon: "error",
            text: "Username, password, or session has expired. Please login again.",
          });
        }

        if (response.status === 200) {
          const newToken = response.data.accessToken;
          const newRefreshToken = response.data.refreshToken;

          sessionStorage.setItem("token", newToken);
          sessionStorage.setItem("rtoken", newRefreshToken);

          axios.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
        } else {
          isRefreshing = false;
          console.log(2)

          Swal.fire({
            icon: "error",
            text: "Username, password, or session has expired. Please login again.",
          });
        }
      } catch (refreshError) {
        console.error("Failed to refresh token:", refreshError);
        isRefreshing = false;
        return <Navigate to='/logout/'></Navigate>
      }
    }
    console.log(4)

    isRefreshing = false;
  }
);
