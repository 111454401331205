import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";


const ResetPasswordModal = ({ show, handleClose, handleResetPasswordModal }) => {
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+={}\[\]|\\:;'"<>,.?/~`]).{8,}$/;
    const enteredPassword = formData.newPassword;

    if (!passwordRegex.test(enteredPassword)) {

      Swal.fire({
        icon: "error",
        text: "Password must contain at leat 8 and at leat 1 Upper Case and 1 Special Character.",
      });
      return; 
    }

    handleResetPasswordModal(formData);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Reset Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="oldPassword">
            <Form.Label>oldPassword</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter old Password"
              name="oldPassword"
              value={formData.oldPassword}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="newPassword">
            <Form.Label>newPassword</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter New Password"
              name="newPassword"
              value={formData.newPassword}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Button className="mt-3" variant="primary" type="submit">
            Reset Password
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ResetPasswordModal;
