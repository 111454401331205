import Navbarheader from "./header";
import SidebarLeft from "./sidebar-left";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaRegFolderOpen } from "react-icons/fa";

function MediaMarketing() {
  return (
    <>
      <Navbarheader></Navbarheader>
      <div className="row">
        <SidebarLeft></SidebarLeft>
        <>
          <div
            className="col-12 col-lg-9 col-xl-10 bg-dark ps-2"
            style={{ fontSize: "medium", minHeight: "1040px", padding: "0" }}
          >
            <Container className="refer-container">
              <Row
                className="tab-header d-flex flex-row align-items-center"
                style={{ marginTop: "17px" }}
              >
                <Col className="d-flex">
                  <FaRegFolderOpen className="icon-sm me-3 mt-1" />
                  <p className="fw-bold">สื่อการตลาด</p>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      </div>
    </>
  );
}

export default MediaMarketing;
