import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaHome } from "react-icons/fa";
import axios from "axios";
import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function Navbarheader() {
  const [userData, setUserdata] = useState([]);
  const [formData, setFormData] = useState({
    reference: "",
  });
  const [showModal, setShowModal] = useState(false);

  const token = sessionStorage.getItem("token") || false;

  const fetchData = async () => {
    try {
      const { data } = await axios.get("/api/partner/summary", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (Array.isArray(data)) {
        setUserdata(data);
      } else {
        const dataArray = Object.entries(data);
        setUserdata(dataArray);
      }
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const referenceRerex = /^([a-z]){1}([a-z,0-9,\-]{5,18})$/;
    const enteredReference = formData.reference;

    if (!referenceRerex.test(enteredReference)) {
      Swal.fire({
        icon: "error",
        text: "reference must at least 6 characters max 18 and start with a-z and consisting of a-z (only Lower Case), 0-9 and - Minus symblo.",
      });
      return;
    }

    handleUpdateReference(formData);
    handleCloseModal();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const navigate = useNavigate();

  const handleUpdateReference = async (e) => {
    try {
      const response = await axios.post(
        `/api/partner/me/reference`,
        {
          reference: e.reference,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status == 200) {
        Swal.fire({
          icon: "success",
          text: "Updated",
        });
        navigate("/");
      } else {
        Swal.fire({
          icon: "error",
          text: "error ล้มเหลว",
        });
      }
      console.log(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCopyClick = async (e) => {
    const urlToCopy = e;
    try {
      await navigator.clipboard.writeText(urlToCopy);
      Swal.fire({
        icon: "success",
        text: "URL copied to clipboard!",
      });
    } catch (error) {
      console.error("Failed to copy URL to clipboard:", error);
      Swal.fire({
        icon: "error",
        text: "Failed to copy URL to clipboard!",
      });
    }
  };

  return (
    <>
      <div
        className="main-detail col-12 col-lg-9 col-xl-10 bg-dark ps-2"
        style={{ fontSize: "medium", minHeight: "1040px", padding: "0" }}
      >
        <Container className="downline-continer">
          {/* ข้อมูลติดต่อ */}
          <Row className="tab-header d-flex flex-row align-items-center">
            <Col className="d-flex">
              <FaHome className="icon-sm me-3 mt-1" />
              <p className="fw-bold">Dashboard</p>
            </Col>
          </Row>

          {/* เนื้อหา ข้อมูลติดต่อ */}
          <Row className="tab-detail-1 align-items-center pt-5 pb-5 mt-2">
            {userData.map((item, index) => (
              <>
                {index !== 0 && (
                  <Row className="col-12">
                    <Col className="d-flex col-3 ps-5 mb-3">
                      <div className="text-start ms-3 align-self-center">
                        <p className="fw-bold">
                          {item[0]}
                        </p>
                      </div>
                    </Col>
                    <Col className="d-flex col-9 ps-5 mb-3">
                      <div className="text-start ms-3">
                        <p className="fw">
                          {Array.isArray(item[1]) && item[1].length > 0 ? (
                            <p></p>
                          ) : (
                            <>
                              {index === 3 && <></> ? (
                                <>
                                  {item[1] == 1 && <>Partner</>}
                                  {item[1] == 2 && <>Senior</>}
                                  {item[1] == 3 && <>Master</>}
                                  {item[1] == 4 && <>Agent</>}
                                  {item[1] == 5 && <>Marketing</>}
                                </>
                              ) : (
                                <>{item[1]}</>
                              )}
                            </>
                          )}
                          {index === 4 && (
                            <Button
                              type="button"
                              className="btn btn-info mx-5 btn btn-primary"
                              onClick={() => handleCopyClick(item[1])}
                            >
                              Copy
                            </Button>
                          )}
                          {index === 5 && (
                            <>
                              {sessionStorage.setItem("selfSharing",item[1])}
                            </>
                          )}
                          {index === 6 && (
                            <Button
                              className="btn btn-info mx-5"
                              variant="primary"
                              type="button"
                              onClick={() => handleOpenModal()}
                            >
                              Edit
                            </Button>
                          )}
                        </p>
                      </div>
                    </Col>
                  </Row>
                )}
              </>
            ))}
          </Row>
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Modal Edit Reference</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="reference">
                  <Form.Label>reference</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter reference min character 5 - 18 allow minus symblo only"
                    name="reference"
                    value={formData.reference}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Button className="mt-3" variant="primary" type="submit">
                  Save Change
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    </>
  );
}

export default Navbarheader;
