import Navbarheader from "./header";
import SidebarLeft from "./sidebar-left";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaQrcode } from "react-icons/fa";
import { FaCopy, FaDownload } from "react-icons/fa";
import { FaHandshakeSimple } from "react-icons/fa6";
import qrCode from "../assets/images/qrcode-ex.png"

function ReferFriend() {
  return (
    <>
      <Navbarheader></Navbarheader>
      <div className="row">
        <SidebarLeft></SidebarLeft>
        <>
          <div
            className="col-12 col-lg-9 col-xl-10 bg-dark ps-2"
            style={{ fontSize: "medium", minHeight: "1040px", padding: "0" }}
          >
            <Container className="refer-container">
              <Row
                className="tab-header d-flex flex-row align-items-center"
                style={{ marginTop: "17px" }}
              >
                <Col className="d-flex">
                  <FaQrcode className="icon-sm me-3 mt-1" />
                  <p className="fw-bold">ลิงค์และคิวอาร์โค้ดแนะนำ</p>
                </Col>
              </Row>

              <div className="row refer-detail mt-2">
                <div className="card_detail me-4">
                  <div className="card_detail-head ps-3 py-3 rounded-top">
                    <FaHandshakeSimple className="me-3" />
                    LINK AND QRCODE
                  </div>
                  <div className="card_detail-body pb-4 rounded-bottom">
                    <div className="text-center pt-4">
                      <p>รหัสตัวแทนหุ้นลม</p>
                      <img
                        className="d-block"
                        src={qrCode}
                        alt="Your Alt Text"
                        style={{ width: "40%", marginLeft: "auto", marginRight: "auto" }}
                      />
                      <div className="btn btn-warning mt-1" style={{ fontSize: "0.8rem"}}>
                        ดาวน์โหลด <FaDownload className="ms-3 mb-1" />
                      </div>
                    </div>
                    <div className="ps-3 pe-3 mb-3 mt-3">
                      <div className="d-flex bg-warning">
                        <FaCopy className="ms-3 me-3 mt-2" />
                        <div
                          className="bg-light w-100 ps-2 text-dark pt-1"
                          style={{ height: "2.2rem" }}
                        >
                          -
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </>
      </div>
    </>
  );
}

export default ReferFriend;
