
import OffcanvasMenu from "./offcanvas";

function Navbarheader() {
  return (
    <>
      <div
        className="col-lg-3 col-xl-2 bg-dark d-none d-lg-block"
        style={{ fontSize: "medium", minHeight: "1040px",borderRight:"1px solid #FFFFFF20" }}
      >
        <OffcanvasMenu></OffcanvasMenu>
      </div>
    </>
  );
}

export default Navbarheader;
