import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import axios from "axios";

const UpdateDownlineReferenceModal = ({ show, handleClose , handleUpdateDownlineRefModal }) => {
  const [formData, setFormData] = useState({
    reference: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    handleUpdateDownlineRefModal(formData);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update downline reference</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="reference">
            <Form.Label>Reference id</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Reference id ของลูกข่ายที่ต้องการสร้างระดับล่าง"
              name="reference"
              value={formData.reference}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Button className="mt-3" variant="primary" type="submit">
            Update Reference
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateDownlineReferenceModal;
